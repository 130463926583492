@tailwind base;
@tailwind components;
@tailwind utilities;

@media (min-width: 1280px){
    .container {
        max-width: 100%;
    }
}

